import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore()
  if (!authStore.isStaff) {
    return navigateTo({ name: 'dashboard' })
  }

  return null
})
